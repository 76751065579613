import { message, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReminders, remindersSelector } from "./remindersSlice";

const PAGE_SIZE = 25;

export function Reminders(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { data, loading, totalPages, totalCount, errors } =
    useSelector(remindersSelector);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getReminders({ id: props.userId, page: pageNum }));
  }, [props.userId]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Service ID",
      dataIndex: "service_id",
      key: "service_id",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => <div>{new Date(date).toLocaleString()}</div>,
    },
    {
      title: "Send in",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => `${Math.round(delay / 3600000)} hrs`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status === 2 ? "Sent" : "Queued"),
    },
  ];

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getReminders({ id: props.userId, page: page }));
  };

  return (
    <>
      {messageContext}
      <Table
        loading={loading}
        bordered
        size="small"
        columns={columns}
        dataSource={data.map((l, i) => {
          return { ...l, ...{ key: i } };
        })}
        pagination={{
          position: ["bottomRight"],
          onChange: onPageChange,
          pageSize: PAGE_SIZE,
          total: totalPages * PAGE_SIZE,
          showSizeChanger: false,
          current: pageNum,
          showTotal: (total) => (
            <span className="fs-2 c-subtext">{`${totalCount} ${
              totalCount === 1 ? "reminder" : "reminders"
            }`}</span>
          ),
        }}
      />
    </>
  );
}
