import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Select, Space, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Info } from "../../components/info/Info";
import { Logo } from "../../components/logo/Logo";
import { Metric } from "../../components/Metric/Metric";
import { usStates } from "../../usStates";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import styles from "./discover.module.css";
import {
  clearErrors,
  clearNodes,
  discoverSelector,
  searchNodes,
} from "./discoverSlice";

const PAGE_SIZE = 50;
const { Option } = Select;

const ALL_CATEGORIES = [
  { id: "arts-culture-and-humanities", name: "Arts, Culture & Humanities" },
  { id: "education", name: "Education" },
  { id: "environment-and-animals", name: "Environment & Animals" },
  { id: "health", name: "Health" },
  { id: "human-services", name: "Human Services" },
  {
    id: "international-foreign-affairs",
    name: "International, Foreign Affairs",
  },
  { id: "public-societal-benefit", name: "Public, Societal Benefit" },
  {
    id: "religion-related",
    name: "Religion Related ",
  },
  {
    id: "mutual-membership-benefit",
    name: "Mutual/Membership Benefit",
  },
  {
    id: "unknown-unclassified",
    name: "Unknown, Unclassified",
  },
];

export function Discover(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { data, totalPages, totalCount, loading, errors } =
    useSelector(discoverSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const [searchString, setSearchString] = useState("");
  const [initSearch, setInitSearch] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    dispatch(clearNodes());
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
    dispatch(clearErrors());
  }, [errors, dispatch]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "discover",
      })
    );
  }, [dispatch]);

  const handleSearch = (page) => {
    setPageNum(page);
    setInitSearch(true);
    let params = {};
    // if (searchString.length === 0) {
    //   messageApi.error("Empty search query");
    //   return;
    // }
    params["query"] = searchString;
    params["categories"] = categories.join(",");
    params["states"] = locations.join(",");
    dispatch(
      searchNodes({
        ...params,
        ...{
          page: page,
          per: PAGE_SIZE,
        },
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        title: "EIN",
        dataIndex: "ein",
        key: "ein",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">{record.ein}</div>
          </Space>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Link to={`/nodes/${record.id}`}>
            <Space size={8}>
              <Logo
                type="node"
                data={{ name: record?.name, src: record?.logo }}
              />
              {text}
            </Space>
          </Link>
        ),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">
              {ALL_CATEGORIES.find((c) => c.id === record.category)?.["name"]}
            </div>
          </Space>
        ),
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">{record.state}</div>
          </Space>
        ),
      },
      {
        title: "Trust Score",
        dataIndex: "score",
        key: "score",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">{record.score}</div>
          </Space>
        ),
      },
      {
        title: "Recommendable",
        dataIndex: "recommend",
        key: "recommend",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">{record.recommend ? "Yes" : "No"}</div>
          </Space>
        ),
      },
    ],
    []
  );

  return (
    <>
      {messageContext}
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Discover</span>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Nonprofits"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row gutter={16}>
            <Col span={9}>
              <Input
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                className={`w-100 ${styles.filter}`}
                placeholder="Search here..."
              />
            </Col>
            <Col span={5}>
              <div>
                <Select
                  mode="multiple"
                  size="large"
                  className={`w-100 ${styles.filter}`}
                  onChange={(value) => setCategories(value)}
                  value={categories}
                  placeholder="Any Category"
                  maxTagCount="responsive"
                >
                  {ALL_CATEGORIES.map((ele) => {
                    return (
                      <Option key={ele.id} value={ele.id}>
                        {ele.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={5}>
              <Select
                mode="multiple"
                size="large"
                className={`w-100 ${styles.filter}`}
                onChange={(value) => setLocations(value)}
                value={locations}
                placeholder="Any State"
                maxTagCount="responsive"
              >
                {usStates.map((ele) => {
                  return (
                    <Option key={ele.code} value={ele.code}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={3}>
              <Select
                size="large"
                className={`w-100 ${styles.filter}`}
                value="nonprofit"
                disabled
              >
                <Option key="nonprofit" value="nonprofit">
                  Nonprofit
                </Option>
              </Select>
            </Col>
            <Col span={2}>
              <Button
                type="primary"
                className={`w-100 ${styles.filter}`}
                icon={<SearchOutlined />}
                onClick={() => handleSearch(1)}
              ></Button>
            </Col>
          </Row>
          {initSearch && (
            <div className="mt-30">
              <Table
                size="small"
                loading={loading}
                bordered
                columns={columns}
                dataSource={data}
                pagination={{
                  position: ["bottomRight"],
                  onChange: (page) => {
                    setPageNum(page);
                    handleSearch(page);
                  },
                  pageSize: PAGE_SIZE,
                  total: totalPages * PAGE_SIZE,
                  showSizeChanger: false,
                  current: pageNum,
                  showTotal: (total) => (
                    <span className="fs-2 c-subtext">{`${totalCount} ${
                      totalCount === 1 ? "node" : "nodes"
                    }`}</span>
                  ),
                }}
              ></Table>
            </div>
          )}
        </div>
      </div>
      {/* // REVERT , Only there until node details is separate to make it possible to open any profile without search */}
      <Info openId={props.id} node={{ id: props.id }} />
    </>
  );
}
