import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tabs,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "../../components/logo/Logo";
import { currentNodeSelector, getNode, updateNode } from "./nodesSlice";
import { Admins } from "./tabs/admins/Admins";
import { Conversations } from "./tabs/conversations/Conversations";
import { Data } from "./tabs/data/Data";
import { Needs } from "./tabs/registry/Needs";
const { Paragraph } = Typography;

export function NodeDetail(props) {
  const [messageApi, messageContext] = message.useMessage();
  const [webForm] = Form.useForm();
  const [domainForm] = Form.useForm();
  const dispatch = useDispatch();
  const { data: node, loading, errors } = useSelector(currentNodeSelector);
  const [domainOpened, setDomainOpened] = useState(false);
  const [webOpened, setWebOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      getNode({
        id: props.id,
      })
    );
  }, [props, dispatch]);

  useEffect(() => {
    if (webOpened && node.id) {
      webForm.setFieldsValue({
        web: node.handles["web"],
        update_domain: true,
      });
    }
  }, [webOpened, node, webForm]);

  useEffect(() => {
    if (domainOpened && node.id) {
      domainForm.setFieldsValue({ domain: node.domain });
    }
  }, [domainOpened, node, domainForm]);

  const update = (values) => {
    let data = {};
    if (values.web) {
      data = {
        handles: { web: values.web.replace(/\s/g, "") },
        update_domain: values.update_domain,
      };
    }
    if (values.domain && values.domain.length > 0) {
      data.domain = values.domain.replace(/\s/g, "");
    }
    dispatch(
      updateNode({
        id: node.id,
        data: data,
      })
    ).then((res) => {
      if (res.type === "nodes/update/fulfilled") {
        messageApi.success({
          content: "Node updated successfully.",
          icon: <CheckCircleOutlined />,
        });
        setDomainOpened(false);
        setWebOpened(false);
      }
    });
  };

  return (
    <>
      {messageContext}
      {node?.id && (
        <div className="container">
          <div className="mb-25 bg-white b-shadow container pb-20">
            <Spin spinning={loading} style={{ padding: 20 }}>
              <Row gutter="24" align="top" wrap>
                <Col span="4" className="ta-c">
                  <Logo
                    type="node"
                    size="xxxl"
                    data={{ name: node?.name, src: node?.logo }}
                  />
                </Col>
                <Col span="20">
                  <div className="fs-6 mb-15">{node?.name}</div>
                  <Row>
                    <Col span={2}>
                      <b>Node ID:</b>
                    </Col>
                    <Col span={22}>{node?.id}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={2}>
                      <b>EIN:</b>
                    </Col>
                    <Col span={22}>
                      {node.ein.slice(0, 2)}-
                      {node.ein.slice(2, node.ein.length)}
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={2}>
                      <b>Website:</b>
                    </Col>
                    <Col span={22}>
                      <a
                        href={node?.handles["web"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {node?.handles["web"]}
                      </a>
                      &nbsp;&nbsp;
                      <EditOutlined
                        className="link"
                        onClick={() => setWebOpened(true)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={2}>
                      <b>Domain:</b>
                    </Col>
                    <Col span={22}>
                      {node?.domain}&nbsp;&nbsp;
                      <EditOutlined
                        className="link"
                        onClick={() => setDomainOpened(true)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={2}>
                      <b>Address:</b>
                    </Col>
                    <Col span={22}>{node?.address}</Col>
                  </Row>
                  <Row className="mt-10">
                    <Col span={2}>
                      <b>Mission:</b>
                    </Col>
                    <Col span={22}>
                      <Paragraph
                        ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      >
                        {node?.about}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Conversations",
                children: (
                  <div className="container bg-white b-shadow">
                    <Conversations id={node?.id} />
                  </div>
                ),
              },
              {
                key: "2",
                label: "Data Sources",
                children: (
                  <div className="container bg-white b-shadow">
                    <Data id={node?.id} />
                  </div>
                ),
              },
              {
                key: "3",
                label: "Registry",
                children: (
                  <div className="container bg-white b-shadow">
                    <Needs id={node?.id} />
                  </div>
                ),
              },
              {
                key: "4",
                label: "Admins",
                children: (
                  <div className="container bg-white b-shadow">
                    <Admins admins={node?.admins} />
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}

      <Modal
        destroyOnClose
        open={webOpened}
        footer={null}
        closable={true}
        width={600}
        onCancel={() => setWebOpened(false)}
      >
        <div className="fs-5 fw-600 mb-25">Edit Website</div>
        <Form
          form={webForm}
          layout="vertical"
          onFinish={update}
          requiredMark={false}
        >
          <Form.Item
            label="Website"
            name="web"
            rules={[{ required: true, message: "Please input website" }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item name="update_domain" valuePropName="checked">
            <Checkbox>Use website to automatically extract the domain</Checkbox>
          </Form.Item>
          <Button
            htmlType="submit"
            className="mt-15"
            type="primary"
            loading={loading}
          >
            Save
          </Button>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        open={domainOpened}
        footer={null}
        closable={true}
        width={600}
        onCancel={() => setDomainOpened(false)}
      >
        <div className="fs-5 fw-600 mb-25">Edit Domain</div>
        <Form
          form={domainForm}
          layout="vertical"
          onFinish={update}
          requiredMark={false}
        >
          <Form.Item label="Domain" name="domain" className="mt-10">
            <Input
              type="text"
              placeholder="Input only the domain part of a website like humanitas.ai, apple.com, etc."
            />
          </Form.Item>
          <Button
            htmlType="submit"
            className="mt-15"
            type="primary"
            loading={loading}
          >
            Save
          </Button>
        </Form>
      </Modal>
    </>
  );
}
