import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { Logo } from "../../components/logo/Logo";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { downloadUsers, getUsers, usersSelector } from "./usersSlice";

const PAGE_SIZE = 50;
const { RangePicker } = DatePicker;

export function Users() {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [exportForm] = Form.useForm();
  const {
    data: users,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(usersSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [exportOpened, setExportOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "users",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: 1,
        search: "",
      })
    );
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <div size="middle">
            <div>{record.id}</div>
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (_, record) => (
          <>
            <Link to={`/users/${record?.id}`}>
              <Space size={8}>
                <Logo
                  type="user"
                  data={{ name: record?.f_name, src: record?.avatar }}
                />
                {`${record?.f_name} ${record?.l_name || ""}`}
              </Space>
            </Link>
          </>
        ),
      },
      {
        title: "Employed at",
        dataIndex: "employers",
        key: "employers",
        render: (_, record) => (
          <>
            {record.employers.length > 0 && (
              <>
                <div size="middle">
                  {record.employers.map((e) => (
                    <div key={e.id}>
                      <Link to={`/orgs/${e.id}`}>{e.name}</Link>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ),
      },
      {
        title: "Admin of",
        dataIndex: "admin",
        key: "admin",
        render: (_, record) => (
          <>
            {record.nodes.length > 0 && (
              <>
                <div className="fs-1 fw-700">Nodes</div>
                <div size="middle" className="mt-5">
                  {record.nodes.map((o) => (
                    <div key={o.id} className="mb-5">
                      <Link to={`/nodes/${o.id}`}>{o.name}</Link>
                    </div>
                  ))}
                </div>
              </>
            )}
            {record.nodes.length > 0 && record.orgs.length > 0 && (
              <div className="mt-15"></div>
            )}
            {record.orgs.length > 0 && (
              <>
                <div className="fs-1 fw-700">Orgs</div>
                <div size="middle" className="mt-5">
                  {record.orgs.map((o) => (
                    <div key={o.id} className="mb-5">
                      <Link to={`/orgs/${o.id}`}>{o.name}</Link>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ),
      },
      {
        title: "Registered on",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => (
          <div size="middle">
            <div>{new Date(text).toLocaleString()}</div>
          </div>
        ),
      },
    ],
    []
  );

  const search = () => {
    setPageNum(1);
    dispatch(
      getUsers({
        page: 1,
        search: searchString,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getUsers({
        page: page,
        search: searchString,
      })
    );
  };

  const exportUsers = (values) => {
    setExportLoading(true);
    dispatch(
      downloadUsers({
        start: values.range[0].toISOString(),
        end: values.range[1].toISOString(),
      })
    ).then((res) => {
      setExportLoading(false);
      if (res.type === "users/download/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `users_${values.range[0].toISOString()}_to_${values.range[1].toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        setExportOpened(false);
        exportForm.resetFields();
      } else {
        messageApi.error("Failed to export users");
      }
    });
  };

  return (
    <>
      {messageContext}
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Users</span>
            </Col>
            <Col className="ta-r">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setExportOpened(true)}
              >
                Export
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Users"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={10}>
              <Row>
                <Col span={12}>
                  <Metric
                    title="New Users (last 7 days PST)"
                    current={metrics["last_7_days"]}
                    loading={metricsLoading}
                  />
                </Col>
                <Col span={12}>
                  <Metric
                    title="Change"
                    current={metrics["last_7_days"]}
                    old={metrics["prev_7_days"]}
                    loading={metricsLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row className="mb-20" gutter={12}>
            <Col span={21}>
              <Input
                placeholder="Search id, name, email..."
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Col>
            <Col span={3}>
              <Button
                icon={<SearchOutlined />}
                className="w-100"
                onClick={search}
                loading={loading}
              >
                Search
              </Button>
            </Col>
          </Row>
          <div className="mt-30">
            <Table
              loading={loading}
              bordered
              size="small"
              columns={columns}
              dataSource={users.map((l, i) => {
                return { ...l, ...{ key: i } };
              })}
              pagination={{
                position: ["bottomRight"],
                onChange: onPageChange,
                pageSize: PAGE_SIZE,
                total: totalPages * PAGE_SIZE,
                showSizeChanger: false,
                current: pageNum,
                showTotal: (total) => (
                  <span className="fs-2 c-subtext">{`${totalCount} ${
                    totalCount === 1 ? "user" : "users"
                  }`}</span>
                ),
              }}
            ></Table>
          </div>
        </div>
      </div>

      <Modal
        title="Export Users"
        open={exportOpened}
        footer={null}
        width={500}
        onCancel={() => {
          setExportOpened(false);
          exportForm.resetFields();
        }}
      >
        <div className="mt-30">
          <Form
            form={exportForm}
            requiredMark={false}
            onFinish={exportUsers}
            layout="vertical"
          >
            <Form.Item
              name="range"
              label="Date Range (in PST)"
              rules={[{ required: true, message: "Please input date range" }]}
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>
            <Form.Item className="ta-r mt-35 mb-10">
              <Button type="primary" htmlType="submit" loading={exportLoading}>
                Export
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
