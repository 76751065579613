import { MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./admins.module.css";
import {
  addAdmins,
  adminsSelector,
  getAdmins,
  removeAdmins,
} from "./adminsSlice";

export function Admins(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [currAdminId, setCurrAdminId] = useState("");
  const { admins, loading, errors } = useSelector(adminsSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.id) {
      dispatch(
        getAdmins({
          id: props.id,
        })
      );
    }
  }, [props.id, dispatch]);

  const deleteAdmin = useCallback(
    (adminId) => {
      dispatch(
        removeAdmins({
          id: props.id,
          adminId: adminId,
        })
      );
    },
    [dispatch, props.id]
  );

  const addAdmin = useCallback(
    (data) => {
      dispatch(
        addAdmins({
          id: props.id,
          data: data,
        })
      ).then(() => {
        messageApi.success("Admin added successfully!");
      });
    },
    [dispatch, props.id]
  );

  const columns = useMemo(
    () => [
      {
        title: "Team Member",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return (
            <div size="middle">
              <div className="mb-0 fs-3">{`${record?.user.f_name} ${record?.user.l_name}`}</div>
              <div className="c-subtext">{record?.user.email}</div>
            </div>
          );
        },
      },
      {
        title: "Permissions",
        dataIndex: "permissions",
        key: "permission",
        render: (text, record) => (
          <Space size="middle">
            <div className="c-grey">
              {record?.registered ? (
                <div>Overview, Activity, Donations, Personalize, Settings</div>
              ) : (
                <div>
                  <i>
                    Invitation Sent.{" "}
                    <span
                      className={styles.resend}
                      onClick={() => {
                        dispatch(
                          addAdmins({
                            id: props.id,
                            data: record.user,
                          })
                        );
                        messageApi.success("Invitation resent successfully!");
                      }}
                    >
                      Resend
                    </span>
                  </i>
                </div>
              )}
            </div>
          </Space>
        ),
      },
      {
        title: "",
        dataIndex: "delete",
        key: "",
        render: (text, record) => (
          <Button
            type="link"
            danger={true}
            onClick={() => {
              setCurrAdminId(record.id);
              setOpenDelete(true);
            }}
          >
            Delete
          </Button>
        ),
      },
    ],
    [props.id, dispatch]
  );

  return (
    <>
      {messageContext}
      <div className="mb-20">
        <Row>
          <Col span={8}></Col>
          <Col span={16} className="ta-r">
            <Button
              type="primary"
              onClick={() => {
                setOpenAdd(true);
              }}
            >
              Add admin
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <Spin spinning={loading}>
          <Table
            size="small"
            columns={columns}
            dataSource={admins.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={false}
            className="mb-20"
          ></Table>
        </Spin>
      </div>

      <Modal
        open={openDelete}
        maskClosable
        onCancel={() => {
          setOpenDelete(false);
        }}
        title="Remove Admin"
        onOk={() => {
          deleteAdmin(currAdminId);
          setOpenDelete(false);
        }}
      >
        <div className="fs-2">
          Are you sure you want to remove this user as admin?
        </div>
      </Modal>

      <Modal
        open={openAdd}
        maskClosable
        onCancel={() => {
          setOpenAdd(false);
          form.resetFields();
        }}
        title="Add Admin"
        footer={null}
      >
        <Form
          form={form}
          size="large"
          onFinish={(data) => {
            addAdmin(data);
            setOpenAdd(false);
            form.resetFields();
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="f_name"
                rules={[
                  {
                    required: true,
                    message: "First name is required!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="prefixIcon" />}
                  placeholder="First Name"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="l_name"
                rules={[
                  {
                    required: true,
                    message: "Surname is required!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="prefixIcon" />}
                  placeholder="Surname"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="prefixIcon" />}
              placeholder="Email"
              type="email"
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="w-100"
            loading={loading}
          >
            Add Admin
          </Button>
        </Form>
      </Modal>
    </>
  );
}
