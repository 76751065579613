import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryTags,
  languages,
  personaTags,
  ServiceConnectionActions,
  ServiceConnectionTypes,
  servingTags,
} from "../../constants";
import { getKeyByValue } from "../../helpers";
import { usStates } from "../../usStates";
import { orgsSelector } from "../orgs/orgsSlice";
import { Connect } from "./Connect";
import { EditHistory } from "./EditHistory";
import styles from "./services.module.css";
import {
  createService,
  deleteService,
  getService,
  searchGeography,
  updateService,
} from "./servicesSlice";

const { TextArea } = Input;
dayjs.extend(utc);
dayjs.extend(timezone);

export function ServiceDetail(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [form] = Form.useForm();
  const [modalOpened, setModalOpened] = useState(false);
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey:
        process.env.NODE_ENV === "development"
          ? "AIzaSyAeFNDHgCLe3HPdusNULKUX5n2ivC0HHJA"
          : "AIzaSyB85I8bH46p3ZTmNBYdnoatQl3iPw25ls0",
    });
  const [placesListOptions, setPlacesListOptions] = useState([]);
  const { orgsList } = useSelector(orgsSelector);
  const [geoResults, setGeoResults] = useState([]);
  const [historyOpened, setHistoryOpened] = useState(false);
  const [connectOpened, setConnectOpened] = useState(false);
  const [currentConnection, setCurrentConnection] = useState();
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    if (props.open) {
      setModalOpened(true);
      if (props.id) {
        setLoading(true);
        dispatch(
          getService({
            id: props.id,
          })
        ).then((res) => {
          if (res.type === "services/show/fulfilled") {
            setLoading(false);
            setService(res.payload);
          } else {
            messageApi.error("Error retrieving service");
          }
        });
      }
    } else {
      reset();
    }
  }, [props.open, props.id]);

  useEffect(() => {
    if (placePredictions.length) {
      setPlacesListOptions(
        placePredictions.map((p) => {
          return { value: p.place_id, label: p.description };
        })
      );
    }
  }, [placePredictions]);

  useEffect(() => {
    if (service?.id) {
      form.setFieldsValue({
        ...service,
        org_id: service?.org?.id || null,
        benefit_calculator_id: service?.benefit_calculator?.id || null,
        tags: service.tags.map((t) => t.split(" > ")),
        serving: service.serving.map((t) => ({ label: t, value: t })),
        personas: service.personas.map((t) => ({ label: t, value: t })),
        coverage: {
          zips: service.coverage.zips,
          cities: service.coverage.cities,
          counties: service.coverage.counties,
          states: service.coverage.states,
        },
        branches: service.branches.map((b) => {
          return {
            ...b,
            operating_hours: b.operating_hours.map((oh) => {
              return {
                ...oh,
                hours: [dayjs(oh.open).tz(b.tz), dayjs(oh.close).tz(b.tz)],
              };
            }),
          };
        }),
      });
      setConnections(service.connections);
    }
  }, [service, form]);

  const reset = () => {
    setModalOpened(false);
    form.resetFields();
    setService(null);
  };

  const update = (values) => {
    let data = values;
    if (data.tags) {
      data.tags = data.tags.map((c) => {
        if (c.length === 1) {
          return `${c[0]} > ${c[0]}`;
        }
        return `${c[0]} > ${c[1]}`;
      });
    }
    if (connections) {
      data.connections = connections;
    }
    data.branches =
      data.branches?.length > 0
        ? data.branches.map((b) => {
            const o =
              b.operating_hours?.length > 0
                ? b.operating_hours.map((oh) => {
                    const oFmt = dayjs(oh.hours[0]).format("YYYY-MM-DD HH:mm");
                    const cFmt = dayjs(oh.hours[1]).format("YYYY-MM-DD HH:mm");
                    return {
                      id: oh.id,
                      day_of_week: oh.day_of_week,
                      open: dayjs.tz(oFmt, b.tz).toISOString(),
                      close: dayjs.tz(cFmt, b.tz).toISOString(),
                    };
                  })
                : [];
            const id = b.id ? b.id : null;
            return {
              id: id,
              name: b.name,
              address: b.address,
              lat: b.lat,
              lng: b.lng,
              tz: b.tz,
              phone: b.phone,
              operating_hours: o,
            };
          })
        : [];
    //Remove undefined fields
    // data = JSON.parse(JSON.stringify(data));
    // Change undefined to null
    for (const key in data) {
      if (data[key] === undefined) {
        data[key] = null;
      }
    }
    if (service?.id) {
      dispatch(updateService({ id: service.id, data })).then((res) => {
        if (res.type === "services/update/fulfilled") {
          messageApi.success({
            content: "Service updated successfully.",
          });
          setModalOpened(false);
          props.onSave(res.payload);
        } else if (res.type === "services/update/rejected") {
          messageApi.error({
            content: "Error updating service",
          });
        }
      });
    } else {
      dispatch(createService({ data })).then((res) => {
        if (res.type === "services/create/fulfilled") {
          messageApi.success({
            content: "Service created successfully.",
          });
          setModalOpened(false);
          props.onSave(res.payload);
        } else if (res.type === "services/create/rejected") {
          messageApi.error({
            content: "Error creating service",
          });
        }
      });
    }
  };

  const getPlaceDetails = (val, index) => {
    placesService.getDetails(
      {
        placeId: val,
      },
      async (p) => {
        try {
          const lat = p.geometry.location.lat();
          const lng = p.geometry.location.lng();
          const tz = await getTimezone(lat, lng);
          form.setFieldsValue({
            branches: form.getFieldValue("branches").map((b, i) => {
              if (i === index) {
                return { ...b, address: p.formatted_address, lat, lng, tz };
              }
              return b;
            }),
          });
        } catch {
          messageApi.error({
            content: "Error retrieving address details",
          });
        }
      }
    );
    // window.alert(
    //   "Changing address might change the timezone of the service. Make sure the operating hours are given in this address's local time."
    // );
  };

  const getTimezone = async (lat, lng) => {
    const res = await fetch(
      `https://api.timezonedb.com/v2.1/get-time-zone?key=9O1PPBQCJNVH&format=json&by=position&lat=${lat}&lng=${lng}`
    );
    const geo = await res.json();
    return geo.zoneName;
  };

  const searchGeo = (query, scope) => {
    setGeoResults([]);
    dispatch(searchGeography({ query, scope })).then((res) => {
      if (res.type === "services/searchGeography/fulfilled") {
        setGeoResults(res.payload.results);
      } else {
        messageApi.error({
          content: "Error retrieving data",
        });
      }
    });
  };

  const prefill247 = (index) => {
    let oh = [];
    [0, 1, 2, 3, 4, 5, 6].forEach((d) => {
      oh.push({
        day_of_week: d,
        hours: [dayjs("00:00", "HH:mm"), dayjs("23:59", "HH:mm")],
      });
    });
    form.setFieldsValue({
      branches: form.getFieldValue("branches").map((b, i) => {
        if (i === index) {
          return { ...b, operating_hours: oh };
        }
        return b;
      }),
    });
  };

  const openConnect = (data) => {
    setCurrentConnection(data);
    setConnectOpened(true);
  };

  const closeConnect = () => {
    setCurrentConnection({});
    setConnectOpened(false);
  };

  const renderConnect = (actionId) => {
    return (
      <>
        {connections.find((c) => c.action_id === actionId) ? (
          <>
            <Tooltip
              title="Use {{response}} to insert the response value in action for API connections. If you want to use a specific key in the response, use {{response.key}}."
              color="#4867b1"
            >
              <Button
                type="link"
                className="w-100"
                onClick={() =>
                  openConnect(connections.find((c) => c.action_id === actionId))
                }
              >
                Connected
              </Button>
            </Tooltip>
          </>
        ) : (
          <Button
            className="w-100"
            onClick={() => openConnect({ action_id: actionId })}
          >
            Connect
          </Button>
        )}
      </>
    );
  };

  const updateConnection = (operation, actionId, connectionTypeId) => {
    const fieldMap = {
      [ServiceConnectionActions["Description"]]: "about",
      [ServiceConnectionActions["Eligibility"]]: "eligibility",
      [ServiceConnectionActions["Next Steps"]]: "next_steps",
    };

    const fieldName = fieldMap[actionId];
    if (!fieldName) return; // Exit if actionId is not mapped

    if (operation === "remove") {
      let val = form.getFieldValue(fieldName);
      if (val?.includes("{{response")) {
        form.setFieldsValue({ [fieldName]: val.replace("{{response}}", "") });
      }
    } else if (operation === "add") {
      if (connectionTypeId === ServiceConnectionTypes["API"]) {
        let val = form.getFieldValue(fieldName);
        if (!val?.includes("{{response")) {
          form.setFieldsValue({
            [fieldName]: val ? `${val}\n{{response}}` : "{{response}}",
          });
        }
      } else {
        form.setFieldsValue({
          [fieldName]: null,
        });
      }
    }
  };

  return (
    <>
      {messageContext}
      <Modal
        title={service?.id ? "Edit Service" : "Create New Service"}
        open={modalOpened}
        maskClosable={false}
        width={1100}
        style={{ top: 0 }}
        footer={null}
        onCancel={() => {
          setModalOpened(false);
          props.onCancel();
        }}
        afterOpenChange={(open) => {
          if (open) {
            setTimeout(() => {
              document.getElementsByClassName("ant-modal-wrap")[0].scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 100);
          }
        }}
        destroyOnClose={true}
      >
        <div className={styles.editContainer}>
          <Form
            form={form}
            disabled={loading}
            requiredMark={false}
            labelCol={{ span: 3 }}
            labelWrap={true}
            className="mt-15"
            onFinish={update}
            initialValues={{
              trust: 1,
              search_terms: "",
              coverage: { zips: [], cities: [], counties: [], states: [] },
            }}
            onFinishFailed={() =>
              messageApi.error("Please fill all required fields")
            }
            onFieldsChange={(changed) => {
              if (changed.length > 0 && changed[0].name[0] === "org_id") {
                if (changed[0].value && form.getFieldValue("node_name")) {
                  form.setFieldsValue({ node_name: null });
                }
              }
              if (changed.length > 0 && changed[0].name[0] === "node_name") {
                if (changed[0].value && form.getFieldValue("org_id")) {
                  form.setFieldsValue({ org_id: null });
                }
              }
            }}
          >
            <Space direction="vertical" size={4} className="w-100">
              <Form.Item label="ID" name="id">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Program Name"
                name="program"
                rules={[{ required: true, message: "Please input value" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Offered By">
                <Row gutter={16} align="middle">
                  <Col span={15}>
                    <Form.Item name="node_name" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="ta-c">
                    (or)
                  </Col>
                  <Col span={7}>
                    <Form.Item name="org_id" noStyle>
                      <Select
                        allowClear
                        showSearch
                        placeholder="Org"
                        options={orgsList.map((o) => ({
                          label: `${o.id} - ${o.name}`,
                          value: o.id,
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Search Terms" name="search_terms">
                <Input />
              </Form.Item>
              <Form.Item
                label="Trust Score"
                name="trust"
                rules={[{ required: true, message: "Please input value" }]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Cost" name="cost">
                <Input />
              </Form.Item>
              <Form.Item label="Amount Range" name="amount">
                <Input />
              </Form.Item>
              <Form.Item label="Time Range" name="time">
                <Input />
              </Form.Item>
              <Form.Item label="Languages" name="languages">
                <Select
                  showSearch
                  mode="multiple"
                  options={languages.map((t) => ({ label: t, value: t }))}
                ></Select>
              </Form.Item>
              <Form.Item label="Tags" name="tags">
                <Cascader
                  showSearch
                  multiple
                  options={Object.keys(categoryTags).map((t) => ({
                    label: t,
                    value: t,
                    children: categoryTags[t].map((c) => ({
                      label: c,
                      value: c,
                    })),
                  }))}
                ></Cascader>
              </Form.Item>
              <Form.Item label="Serving" name="serving">
                <Select
                  showSearch
                  mode="multiple"
                  options={servingTags.map((t) => ({ label: t, value: t }))}
                ></Select>
              </Form.Item>
              <Form.Item label="Target Personas" name="personas">
                <Select
                  showSearch
                  mode="multiple"
                  options={personaTags.map((t) => ({ label: t, value: t }))}
                ></Select>
              </Form.Item>
              <Form.Item label="Description">
                <Row gutter={16} align="middle">
                  <Col span={18}>
                    <Form.Item name="about" noStyle>
                      <TextArea autoSize />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="ta-c">
                    (and)
                  </Col>
                  <Col span={4}>
                    {renderConnect(ServiceConnectionActions["Description"])}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Eligibility">
                <Row gutter={16} align="middle">
                  <Col span={18}>
                    <Form.Item name="eligibility" noStyle>
                      <TextArea autoSize />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="ta-c">
                    (and)
                  </Col>
                  <Col span={4}>
                    {renderConnect(ServiceConnectionActions["Eligibility"])}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Next Steps">
                <Row gutter={16} align="middle">
                  <Col span={18}>
                    <Form.Item name="next_steps" noStyle>
                      <TextArea autoSize />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="ta-c">
                    (and)
                  </Col>
                  <Col span={4}>
                    {renderConnect(ServiceConnectionActions["Next Steps"])}
                  </Col>
                </Row>
              </Form.Item>
              <div className="fw-600 mb-10">Contact Info</div>
              <Form.Item label="Website" name="web">
                <Input />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input type="email" />
              </Form.Item>
              <Form.Item label="Twitter" name="tw">
                <Input />
              </Form.Item>
              <Form.Item label="Facebook" name="fb">
                <Input />
              </Form.Item>
              <div className="fw-600 mb-10">Coverage</div>
              <Form.Item label="Zips" name={["coverage", "zips"]}>
                <Select
                  placeholder="Zips"
                  allowClear
                  showSearch
                  mode="multiple"
                  options={geoResults.map((t) => ({
                    label: t,
                    value: t,
                  }))}
                  onSearch={(val) => {
                    searchGeo(val, "zips");
                  }}
                  onDropdownVisibleChange={() => setGeoResults([])}
                ></Select>
              </Form.Item>
              <Form.Item label="Cities" name={["coverage", "cities"]}>
                <Select
                  placeholder="Cities"
                  allowClear
                  showSearch
                  mode="multiple"
                  options={geoResults.map((t) => ({
                    label: t,
                    value: t,
                  }))}
                  onSearch={(val) => {
                    searchGeo(val, "cities");
                  }}
                  onDropdownVisibleChange={() => setGeoResults([])}
                ></Select>
              </Form.Item>
              <Form.Item label="Counties" name={["coverage", "counties"]}>
                <Select
                  placeholder="Counties"
                  allowClear
                  showSearch
                  mode="multiple"
                  options={geoResults.map((t) => ({
                    label: t,
                    value: t,
                  }))}
                  onSearch={(val) => {
                    searchGeo(val, "counties");
                  }}
                  onDropdownVisibleChange={() => setGeoResults([])}
                ></Select>
              </Form.Item>
              <Form.Item label="States" name={["coverage", "states"]}>
                <Select
                  placeholder="States"
                  showSearch
                  mode="multiple"
                  options={usStates.map((t) => ({
                    label: t.name,
                    value: t.code,
                  }))}
                ></Select>
              </Form.Item>

              <Form.List name="branches">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Space className="mb-10">
                      <div className="fw-600">Branches</div>
                      <Button type="link" onClick={add}>
                        <PlusSquareOutlined />
                      </Button>
                    </Space>
                    {fields.map((field, index) => (
                      <div key={index} className={styles.branchBox}>
                        <Form.Item hidden name={[index, "id"]} noStyle />
                        <Space>
                          <div className="fw-500">Branch - {index + 1}</div>
                          <Button
                            danger
                            type="link"
                            onClick={() => remove(field.name)}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Space>
                        <Row gutter={12} align="middle" className="mt-10">
                          <Col span={7}>
                            <Form.Item
                              name={[index, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Input placeholder="Name" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name={[index, "address"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                suffixIcon={null}
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                notFoundContent={null}
                                placeholder="Address"
                                onSearch={(val) => {
                                  getPlacePredictions({
                                    input: val,
                                    componentRestrictions: {
                                      // Restrict search to US territories
                                      country: ["us", "pr", "vi", "gu", "mp"],
                                    },
                                  });
                                }}
                                options={placesListOptions}
                                onChange={(val) => getPlaceDetails(val, index)}
                              />
                            </Form.Item>
                            <Form.Item name={[index, "lat"]} hidden noStyle />
                            <Form.Item name={[index, "lng"]} hidden noStyle />
                            <Form.Item name={[index, "tz"]} hidden noStyle />
                          </Col>
                          <Col span={5}>
                            <Form.Item name={[index, "phone"]}>
                              <Input type="tel" placeholder="Phone" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div>
                          <Form.List name={[index, "operating_hours"]}>
                            {(
                              ohFields,
                              { add: addOh, remove: removeOh },
                              { errors }
                            ) => (
                              <>
                                <Space className="mb-10">
                                  <div className="fw-400">
                                    Operating Hours (In address's local time)
                                  </div>
                                  <Button type="link" onClick={addOh}>
                                    <PlusSquareOutlined />
                                  </Button>
                                </Space>
                                {ohFields.length === 0 && (
                                  <div>
                                    <Button
                                      size="small"
                                      onClick={() => prefill247(index)}
                                    >
                                      Click here to prefill 24/7 availability
                                    </Button>
                                  </div>
                                )}
                                {ohFields.map((ohField, ohIndex) => (
                                  <Row gutter={12} align="middle" key={ohIndex}>
                                    <Col span={3} className="ta-r">
                                      <Form.Item
                                        hidden
                                        name={[ohIndex, "id"]}
                                        noStyle
                                      />
                                      <Form.Item>Open/Close :</Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        name={[ohIndex, "day_of_week"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please input value",
                                          },
                                        ]}
                                      >
                                        <Select
                                          placeholder="Day of week"
                                          options={[
                                            { label: "Sunday", value: 0 },
                                            { label: "Monday", value: 1 },
                                            { label: "Tuesday", value: 2 },
                                            { label: "Wednesday", value: 3 },
                                            { label: "Thursday", value: 4 },
                                            { label: "Friday", value: 5 },
                                            { label: "Saturday", value: 6 },
                                          ]}
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                      <Form.Item
                                        name={[ohIndex, "hours"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please input value",
                                          },
                                        ]}
                                      >
                                        <TimePicker.RangePicker
                                          format="HH:mm"
                                          placeholder={["Open", "Close"]}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                      <Form.Item>
                                        <Button
                                          danger
                                          type="link"
                                          onClick={() => removeOh(ohField.name)}
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </Space>
            <Divider plain className="fs-1">
              <p className="fs-3 mb-0 c-divider">Annotators</p>
            </Divider>
            <Form.Item label="Notes" name="notes">
              <TextArea autoSize />
            </Form.Item>
            <div className="ta-c">
              <Button size="small" onClick={() => setHistoryOpened(true)}>
                Service edit history
              </Button>
            </div>
            <Divider />
            <Row className="mt-35" justify="space-between" wrap>
              <Col span={6}>
                <Button
                  onClick={() => {
                    setModalOpened(false);
                    props.onCancel();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={6} className="ta-c">
                {service?.id && (
                  <Popconfirm
                    title="Delete service"
                    description="Are you sure to delete this service? You cannot undo this action."
                    onConfirm={() => {
                      dispatch(deleteService({ id: service.id })).then(
                        (res) => {
                          if (res.type === "services/delete/fulfilled") {
                            messageApi.success({
                              content: "Service deleted successfully.",
                            });
                            setModalOpened(false);
                            props.onSave();
                          } else if (res.type === "services/delete/rejected") {
                            messageApi.error({
                              content: "Error deleting service",
                            });
                          }
                        }
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger>Delete</Button>
                  </Popconfirm>
                )}
              </Col>
              <Col span={6} className="ta-r">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        open={historyOpened}
        zIndex={1001}
        maskClosable
        onCancel={() => setHistoryOpened(false)}
        footer={null}
        width={1300}
        title={`Service Edit History - ${service?.id}`}
        destroyOnClose={true}
        style={{ top: 0 }}
      >
        <div className="pt-20">
          <EditHistory id={service?.id} />
        </div>
      </Modal>

      <Modal
        title={`Connect ${getKeyByValue(
          ServiceConnectionActions,
          currentConnection?.action_id
        )}`}
        zIndex={1001}
        open={connectOpened}
        footer={null}
        destroyOnClose={true}
        onCancel={() => closeConnect()}
      >
        <Connect
          data={currentConnection}
          onSave={(values) => {
            setConnections((prevConnections) =>
              prevConnections.some((c) => c.action_id === values.action_id)
                ? prevConnections.map((c) =>
                    c.action_id === values.action_id ? values : c
                  )
                : [...prevConnections, values]
            );
            updateConnection(
              "add",
              values.action_id,
              values.connection_type_id
            );
            closeConnect();
          }}
          onDelete={(actionId) => {
            setConnections(connections.filter((c) => c.action_id !== actionId));
            updateConnection("remove", actionId);
            closeConnect();
          }}
        />
      </Modal>
    </>
  );
}
