import { CloseCircleOutlined } from "@ant-design/icons";
import { Image, Space, Table, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNeeds, getNeeds, needsSelector } from "./registrySlice";

export function Needs(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { data: needs, loading, errors } = useSelector(needsSelector);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (props.id) {
      dispatch(clearNeeds());
      dispatch(getNeeds({ nodeId: props.id, approved: true }));
    }
  }, [dispatch, props.id]);

  const columns = [
    {
      title: "Item Name",
      dataIndex: ["catalogue"],
      key: "name",
      render: (c) => (
        <Space align="center">
          <Image src={c.image} height={24} width={24} preview={false} />
          <div>{c.name}</div>
        </Space>
      ),
    },
    {
      title: "Size",
      dataIndex: "sizes",
      key: "sizes",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (f) => {
        switch (f) {
          case 1:
            return "Every Week";
          case 2:
            return "Every 2 Weeks";
          case 3:
            return "Every 4 Weeks";
          case 4:
            return "Every 6 Weeks";
          default:
            return "";
        }
      },
    },
    {
      title: "Need Status",
      dataIndex: "status",
      key: "status",
      render: (s) => {
        if (s) {
          return <>Active</>;
        }
        return <>Disabled</>;
      },
    },
  ];

  return (
    <>
      {messageContext}
      <Table
        pagination={{ position: ["none", "none"] }}
        dataSource={needs}
        loading={loading}
        bordered
        size="small"
        columns={columns}
      />
    </>
  );
}
