import { MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./info.module.css";
import { addAdmins, getInfo, infoSelector } from "./infoSlice";

const { Link } = Typography;

export function Info(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { data, loading, admins } = useSelector(infoSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [form] = Form.useForm();

  const openModal = () => {
    setIsOpen(true);
    dispatch(
      getInfo({
        id: props.node.id,
      })
    );
  };

  /* // REVERT , Only there until node details is separate to make it possible to open any profile without search */
  useEffect(() => {
    if (props.openId) {
      openModal();
    }
  }, [props.openId]);

  const addAdmin = (data) => {
    dispatch(
      addAdmins({
        id: props.node.id,
        data: data,
      })
    ).then((res) => {
      if (res.type === "addAdmins/rejected") {
        messageApi.error(res.payload.errors[0]);
      } else {
        messageApi.success("Admin added successfully!");
      }
    });
  };

  return (
    <>
      {messageContext}
      <Link onClick={() => openModal()} className="c-grey">
        {props.node?.name}
      </Link>
      <Modal
        title={null}
        open={isOpen}
        className={`${styles.modalContainer}`}
        maskClosable
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <Spin spinning={loading} style={{ padding: 20 }}>
          <Row gutter="20" align="top">
            <Col span="6" className="ta-c">
              <Avatar src={data?.logo} size={100} className="mb-30" />
              {data &&
                (admins.length > 0 ? (
                  <Button type="primary" disabled size="small">
                    {data.claimed ? "Claimed" : "Claim Email Sent"}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setOpenAdd(true)}
                  >
                    Send Claim Email
                  </Button>
                ))}
            </Col>
            <Col span="18">
              <div className="fs-4 mb-5">{data?.name}</div>
              <div>{data?.about}</div>
              <Divider />
              {data && (
                <>
                  <div className="fs-2 mb-5">
                    <span className="fw-600">EIN:</span> {data?.ein}
                  </div>
                  <div className="fs-2 mb-5">
                    <span className="fw-600">Category:</span>{" "}
                    {data?.category
                      ? data.category.charAt(0).toUpperCase() +
                        data.category.slice(1)
                      : "-"}
                  </div>
                  <div className="fs-2 mb-0">
                    {" "}
                    <span className="fw-600">Donations:</span>{" "}
                    {data?.donation_c}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal
        open={openAdd}
        maskClosable
        onCancel={() => {
          setOpenAdd(false);
          form.resetFields();
        }}
        title="Send Claim Email"
        footer={null}
      >
        <Form
          form={form}
          size="large"
          onFinish={(data) => {
            addAdmin(data);
            setOpenAdd(false);
            form.resetFields();
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="f_name"
                rules={[
                  {
                    required: true,
                    message: "First name is required!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="prefixIcon" />}
                  placeholder="First Name"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="l_name"
                rules={[
                  {
                    required: true,
                    message: "Surname is required!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="prefixIcon" />}
                  placeholder="Surname"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="prefixIcon" />}
              placeholder="Email"
              type="email"
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="w-100"
            loading={loading}
          >
            Send
          </Button>
        </Form>
      </Modal>
    </>
  );
}
