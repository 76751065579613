import {
  DeleteOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ServiceConnectionActions,
  ServiceConnectionTypes,
} from "../../constants";
import { authSelector } from "../auth/authSlice";
import { benefitCalculatorsSelector } from "./servicesSlice";

const { TextArea } = Input;

export function Connect(props) {
  const [connectForm] = Form.useForm();
  const { currentUser } = useSelector(authSelector);
  const connectType = Form.useWatch("connection_type_id", connectForm);
  const { data: benefitCalculatorsList } = useSelector(
    benefitCalculatorsSelector
  );
  const [testOpened, setTestOpened] = useState(false);
  const [testParams, setTestParams] = useState([]);
  const [testHeaders, setTestHeaders] = useState([]);
  const [testLoading, setTestLoading] = useState(true);
  const [testResponse, setTestResponse] = useState({});

  useEffect(() => {
    if (props.data) {
      let connection = structuredClone(props.data);
      if (connection.meta?.params) {
        connection.meta.params = Object.entries(connection.meta.params).map(
          ([key, value]) => ({ key, value })
        );
      }
      if (connection.meta?.headers) {
        connection.meta.headers = Object.entries(connection.meta.headers).map(
          ([key, value]) => ({ key, value })
        );
      }
      connectForm.setFieldsValue(connection);
    }
  }, [props.data]);

  const reformatObj = (obj) => {
    return obj.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
  };

  const handleSubmit = (values) => {
    if (Array.isArray(values?.meta?.params)) {
      values.meta.params = reformatObj(values.meta.params);
    } else {
      delete values?.meta?.params;
    }
    if (Array.isArray(values?.meta?.headers)) {
      values.meta.headers = reformatObj(values.meta.headers);
    } else {
      delete values?.meta?.headers;
    }
    props.onSave(values);
  };

  const replaceParameters = (str) => {
    return str.replace("{{user_id}}", currentUser.id);
  };

  const addDefaultHeaders = (headers) => {
    headers = headers || [];
    if (!headers.find((h) => h.key?.toLowerCase() === "content-type")) {
      headers.push({ key: "Content-Type", value: "application/json" });
    }
    if (!headers.find((h) => h.key?.toLowerCase() === "accept")) {
      headers.push({ key: "Accept", value: "application/json" });
    }
    return headers;
  };

  const testConnection = () => {
    let params = connectForm.getFieldValue("meta")?.params?.map((h) => ({
      key: h.key,
      value: replaceParameters(h.value),
    }));
    let headers = connectForm.getFieldValue("meta")?.headers?.map((h) => ({
      key: h.key,
      value: replaceParameters(h.value),
    }));
    headers = addDefaultHeaders(headers);
    setTestHeaders(headers);
    setTestParams(params);
    setTestOpened(true);
    fetch(
      `${connectForm.getFieldValue("url")}?${new URLSearchParams(
        reformatObj(params)
      )}
      `,
      {
        method: "GET",
        headers: reformatObj(headers),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        setTestResponse({
          success: res.status >= 200 && res.status < 300,
          status: res.status,
          body: data,
        });
        setTestLoading(false);
      })
      .catch((res) => {
        setTestResponse({
          success: false,
          status: res.status,
          body: res.message,
        });
        setTestLoading(false);
      });
  };

  return (
    <>
      <Form
        form={connectForm}
        className="mt-30"
        requiredMark={false}
        labelCol={{ span: 3 }}
        labelWrap={true}
        onFinish={handleSubmit}
      >
        <Form.Item hidden name="id"></Form.Item>
        <Form.Item hidden name="action_id"></Form.Item>
        <Form.Item name="connection_type_id" label="Type">
          <Select
            options={
              props.data.action_id === ServiceConnectionActions["Eligibility"]
                ? Object.entries(ServiceConnectionTypes).map(
                    ([label, value]) => ({
                      label,
                      value,
                    })
                  )
                : [
                    {
                      value: 1,
                      label: "API",
                    },
                  ]
            }
          />
        </Form.Item>
        {connectType === ServiceConnectionTypes["API"] && (
          <>
            <Form.Item
              name="url"
              label="URL"
              rules={[{ required: true, message: "Please input value" }]}
            >
              <TextArea autoSize />
            </Form.Item>
            <div>
              <Form.List name={["meta", "params"]}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Space className="mb-10">
                      <div className="fw-600">
                        Custom Params&nbsp;{" "}
                        <Tooltip
                          title="{{user_id}} will be replaced with the User's ID"
                          color="#4867b1"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </div>
                      <Button type="link" onClick={add}>
                        <PlusSquareOutlined />
                      </Button>
                    </Space>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <Row gutter={12}>
                          <Col span={11}>
                            <Form.Item
                              name={[index, "key"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Input placeholder="Key" />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              name={[index, "value"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Input placeholder="Value" />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Button
                              danger
                              type="link"
                              onClick={() => remove(field.name)}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
            <div className="mt-15">
              <Form.List name={["meta", "headers"]}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Space className="mb-10">
                      <div className="fw-600">
                        Custom Headers&nbsp;{" "}
                        <Tooltip
                          title="{{user_id}} will be replaced with the User's ID"
                          color="#4867b1"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </div>
                      <Button type="link" onClick={add}>
                        <PlusSquareOutlined />
                      </Button>
                    </Space>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <Row gutter={12}>
                          <Col span={11}>
                            <Form.Item
                              name={[index, "key"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Input placeholder="Key" />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <Form.Item
                              name={[index, "value"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input value",
                                },
                              ]}
                            >
                              <Input placeholder="Value" />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Button
                              danger
                              type="link"
                              onClick={() => remove(field.name)}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </>
        )}
        {connectType === ServiceConnectionTypes["Calculator"] && (
          <Form.Item name="benefit_calculator_id" label="Name">
            <Select
              options={benefitCalculatorsList.map((o) => ({
                label: o.name,
                value: o.id,
              }))}
            />
          </Form.Item>
        )}
        <Row gutter={16} className="mt-20">
          <Col span={8}>
            {props.data.connection_type_id && (
              <div className="ta-l">
                <Button
                  danger
                  onClick={() => props.onDelete(props.data.action_id)}
                >
                  Remove
                </Button>
              </div>
            )}
          </Col>
          <Col span={8} className="ta-c">
            {connectType === ServiceConnectionTypes["API"] && (
              <Button type="link" onClick={() => testConnection()}>
                Test
              </Button>
            )}
          </Col>
          <Col span={8}>
            {connectType && (
              <div className="ta-r">
                {connectType === ServiceConnectionTypes["API"] ? (
                  <Popconfirm
                    title="Have you tested the API and it's working?"
                    onConfirm={() => connectForm.submit()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary">Connect</Button>
                  </Popconfirm>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Connect
                  </Button>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Form>

      <Modal
        open={testOpened}
        width={800}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setTestOpened(false)}
      >
        <Descriptions
          bordered
          size="small"
          title="Test Connection"
          column={1}
          items={[
            {
              key: "1",
              label: "URL",
              children: connectForm.getFieldValue("url"),
            },
            {
              key: "2",
              label: "Params",
              children: (
                <>
                  {testParams?.map((p, i) => (
                    <div key={i}>
                      {p.key}: {p.value}
                    </div>
                  ))}
                </>
              ),
            },
            {
              key: "3",
              label: "Headers",
              children: (
                <>
                  {testHeaders?.map((h, i) => (
                    <div key={i}>
                      {h.key}: {h.value}
                    </div>
                  ))}
                </>
              ),
            },
          ]}
        />
        {testLoading ? (
          <div className="ta-c mt-30 mb-20">
            <Spin />
          </div>
        ) : (
          <div>
            {!testResponse?.success && (
              <Alert showIcon message="Error" type="error" className="mt-20" />
            )}
            <div className="mt-20">
              <Card title={`Status: ${testResponse?.status}`} size="small">
                <pre
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {JSON.stringify(testResponse?.body, null, 2)}
                </pre>
              </Card>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
