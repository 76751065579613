import { EditOutlined, LockOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, getUser, update } from "../auth/authSlice";
import styles from "./Profile.module.css";

const { Paragraph } = Typography;

export function Profile() {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [user, setUser] = useState({});
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const { currentUser, loading, errors } = useSelector(authSelector);

  const changeUser = (data) => {
    setUser(Object.assign({}, user, data));
    dispatch(update(data)).then(() => {
      messageApi.success("Profile updated successfully!");
    });
  };

  const changeAvatar = (e) => {
    let fd = new FormData();
    fd.append("avatar", e.file);
    dispatch(update(fd)).then((res) => {
      if (res.type === "user/update/fulfilled") {
        messageApi.success("Profile updated successfully!");
      }
    });
  };

  const changePassword = () => {
    if (confPass !== newPass) {
      messageApi.error("Passwords don't match");
      return;
    }
    if (newPass.length < 6) {
      messageApi.error("Passwords should have a minimum of 6 characters");
      return;
    }
    dispatch(update({ password: newPass })).then(() => {
      setNewPass("");
      setConfPass("");
      messageApi.success("Password updated successfully!");
    });
  };

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <>
      {messageContext}
      <div className="container-sm ta-c">
        <Spin spinning={loading}>
          <div className="mt-5">
            <Upload
              accept="image/*"
              maxCount={1}
              customRequest={changeAvatar}
              showUploadList={false}
              className="pointer"
            >
              <Avatar size={90} src={currentUser.avatar}></Avatar>
              <EditOutlined className="edit-upload" />
            </Upload>
          </div>
          <Divider plain className="fs-1">
            <p className="fs-3 mb-0 c-divider">Personal Details</p>
          </Divider>
          <div className={`${styles.pContent} mh-a mt-15`}>
            <Row gutter="20">
              <Col span="12">
                <Paragraph
                  className="fs-2 mb-10"
                  strong
                  editable={{
                    onChange: (val) => {
                      if (val.length > 0) {
                        changeUser({ f_name: val });
                      }
                    },
                    tooltip: "click to edit first name",
                  }}
                >
                  {user.f_name || "First Name"}
                </Paragraph>
              </Col>
              <Col span="12">
                <Paragraph
                  className="fs-2 mb-10"
                  strong
                  editable={{
                    onChange: (val) => {
                      if (val.length > 0) {
                        changeUser({ l_name: val });
                      }
                    },
                    tooltip: "click to edit surname",
                  }}
                >
                  {user.l_name || "Surname"}
                </Paragraph>
              </Col>
            </Row>
            <Paragraph
              className="fs-2"
              strong
              // editable={{
              //   onChange: (val) => {
              //     if (val.length > 0) {
              //       changeUser({ email: val });
              //     }
              //   },
              //   tooltip: "click to edit email",
              // }}
            >
              {user.email}
            </Paragraph>
          </div>
          <Divider plain className="fs-1">
            <p className="fs-3 mb-0 c-divider">Update Password</p>
          </Divider>
          <div className={`${styles.pContent} mh-a mt-30`}>
            <Space direction="vertical" size="middle" className="w-100">
              <Input.Password
                type="password"
                placeholder="New password"
                value={newPass}
                prefix={<LockOutlined className="prefixIcon" />}
                onChange={(e) => setNewPass(e.target.value)}
              />
              <Input.Password
                type="password"
                placeholder="Confirm New password"
                value={confPass}
                prefix={<LockOutlined className="prefixIcon" />}
                onChange={(e) => setConfPass(e.target.value)}
              />
              <Button
                type="primary"
                className="w-100"
                onClick={() => changePassword()}
              >
                Change Password
              </Button>
            </Space>
          </div>
        </Spin>
      </div>
    </>
  );
}
