import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  Row,
  Statistic,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./services.module.css";
import {
  downloadServiceInteractions,
  getServiceInteractionMetrics,
  getServiceReviews,
} from "./servicesSlice";

const { RangePicker } = DatePicker;
const PAGE_SIZE = 25;

export function Reviews(props) {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [form] = Form.useForm();
  const [interactionMetrics, setInteractionMetrics] = useState({});
  const [reviews, setReviews] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [exportOpened, setExportOpened] = useState(false);

  useEffect(() => {
    if (props.id) {
      loadReviews(pageNum);
      loadInteractionMetrics();
    }
  }, [props.id]);

  const loadReviews = (page) => {
    dispatch(getServiceReviews({ id: props.id, page, per: PAGE_SIZE })).then(
      (res) => {
        if (res.type === "services/getReviews/fulfilled") {
          setReviews(res.payload.reviews);
          setTotalPages(res.payload.total_pages);
          setTotalCount(res.payload.total_count);
        } else {
          messageApi.error("Failed to load reviews");
        }
      }
    );
  };

  const loadInteractionMetrics = () => {
    dispatch(getServiceInteractionMetrics({ id: props.id })).then((res) => {
      if (res.type === "services/getServiceInteractionMetrics/fulfilled") {
        setInteractionMetrics(res.payload);
      } else {
        messageApi.error("Failed to load interactions");
      }
    });
  };

  const exportInteractions = (values) => {
    dispatch(
      downloadServiceInteractions({
        id: props.id,
        start: values.range[0].toISOString(),
        end: values.range[1].toISOString(),
      })
    ).then((res) => {
      if (res.type === "services/downloadServiceInteractions/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `interactions_${
            props.id
          }_${values.range[0].toISOString()}_to_${values.range[1].toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        setExportOpened(false);
        form.resetFields();
      } else {
        messageApi.error("Failed to export interactions");
      }
    });
  };

  const onPageChange = (page) => {
    setPageNum(page);
    loadReviews(page);
  };

  return (
    <>
      {messageContext}
      <div className={`${styles.impBox} mt-20`}>
        <Row>
          {Object.keys(interactionMetrics).map((key) => (
            <Col span={6} key={key}>
              <Statistic
                title={key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
                value={interactionMetrics[key]}
                valueStyle={{
                  fontSize: "20px",
                  height: "30px",
                  lineHeight: "20px",
                }}
              />
            </Col>
          ))}
        </Row>
        <div className="ta-c mt-15">
          <Button size="small" onClick={() => setExportOpened(true)}>
            Export Interactions
          </Button>
        </div>
      </div>
      <Divider />
      <Table
        bordered
        size="small"
        columns={[
          {
            title: "User",
            dataIndex: "user_id",
            key: "user_id",
            render: (id) => (
              <>
                <Link to={`/users/${id}`}>{id}</Link>
              </>
            ),
          },
          {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
          },
          {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
          },
          {
            title: "Reviewed At",
            dataIndex: "created_at",
            key: "created_at",
            render: (at) => new Date(at).toLocaleString(),
          },
        ]}
        dataSource={reviews.map((l, i) => {
          return { ...l, ...{ key: i } };
        })}
        pagination={{
          position: ["bottomRight"],
          onChange: onPageChange,
          pageSize: PAGE_SIZE,
          total: totalPages * PAGE_SIZE,
          showSizeChanger: false,
          current: pageNum,
          showTotal: () => (
            <span className="fs-2 c-subtext">{`${totalCount} ${
              totalCount === 1 ? "review" : "reviews"
            }`}</span>
          ),
        }}
      />

      <Modal
        title="Export Interactions"
        open={exportOpened}
        footer={null}
        width={500}
        onCancel={() => {
          setExportOpened(false);
          form.resetFields();
        }}
      >
        <div className="mt-30">
          <Form
            form={form}
            requiredMark={false}
            onFinish={exportInteractions}
            layout="vertical"
          >
            <Form.Item
              name="range"
              label="Date Range (in PST)"
              rules={[{ required: true, message: "Please input date range" }]}
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>
            <Form.Item className="ta-r mt-35 mb-10">
              <Button type="primary" htmlType="submit">
                Export
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
