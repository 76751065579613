/* eslint-disable react-hooks/exhaustive-deps */
import { ShopOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Typography, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUpdatedOrg,
  createOrg,
  orgsUpdateSelector,
  updateOrg,
} from "./orgsSlice";

const { Text } = Typography;

export function OrgEditModal(props) {
  const [messageApi, messageContext] = message.useMessage();
  const { data, loading, errors } = useSelector(orgsUpdateSelector);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.open && !props.create) {
      form.setFieldsValue({ name: props.data.name, rate: props.data.rate });
    }
  }, [props.open]);

  useEffect(() => {
    if (!props.create && data?.id) {
      props.onSuccess(data);
    }
  }, [data]);

  return (
    <>
      {messageContext}
      <Modal
        open={props.open}
        maskClosable
        onCancel={() => props.onCancel()}
        afterClose={() => dispatch(clearUpdatedOrg())}
        title={props.create ? "New Organization" : "Update Organization"}
        footer={null}
      >
        {props.create && data?.id ? (
          <>
            <div className="mb-30">
              <Space direction="vertical">
                <Text className="fs-3">
                  API key generated for <b>{data.name}</b>.{" "}
                  {data.rate === -1
                    ? "Unlimited number of API calls allowed. "
                    : `Limited to ${data.rate} calls per month. `}
                  This key won't be visible again on this dashboard!
                </Text>
                <Text code={true} copyable={true} className="fs-5">
                  {data.key}
                </Text>
              </Space>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="w-100"
              onClick={(e) => {
                props.onSuccess(data);
              }}
            >
              Done
            </Button>
          </>
        ) : (
          <Form
            form={form}
            size="large"
            className="mt-20"
            onFinish={(data) => {
              if (props.create) {
                dispatch(
                  createOrg({
                    data: data,
                  })
                );
              } else {
                dispatch(
                  updateOrg({
                    id: props.data.id,
                    data: data,
                  })
                );
              }
            }}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required!",
                },
              ]}
            >
              <Input
                prefix={<ShopOutlined className="prefixIcon" />}
                placeholder="Name"
                type="text"
              />
            </Form.Item>
            {/* <Form.Item
            name="rate"
            rules={[
              {
                required: true,
                message: "Rate is required!",
              },
            ]}
          >
            <Input
              prefix={<NumberOutlined className="prefixIcon" />}
              placeholder="API limit per month (-1 if unlimited)"
              type="number"
            />
          </Form.Item> */}

            <Button
              type="primary"
              htmlType="submit"
              className="w-100"
              loading={loading}
            >
              {props.create ? "Create" : "Update"}
            </Button>
          </Form>
        )}
      </Modal>{" "}
    </>
  );
}
