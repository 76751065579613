import { Col, Row, Space, Table, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { Logo } from "../../components/logo/Logo";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { allNodesSelector, getNodes } from "./nodesSlice";

const PAGE_SIZE = 50;

export function Nodes() {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const {
    data: nodes,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(allNodesSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "nodes",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getNodes({
        page: 1,
      })
    );
  }, [dispatch]);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getNodes({
        page: page,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Link to={`/nodes/${record.id}`}>
            <Space size={8}>
              <Logo
                type="node"
                data={{ name: record?.name, src: record?.avatar }}
              />
              {text}
            </Space>
          </Link>
        ),
      },
      {
        title: "Data Sources",
        dataIndex: "data_sources_c",
        key: "data_sources_c",
      },
      {
        title: "Conversations",
        dataIndex: "conversations_c",
        key: "conversations_c",
      },
      {
        title: "Registry (No. Needs)",
        dataIndex: "needs_c",
        key: "needs_c",
      },
    ],
    []
  );

  return (
    <>
      {messageContext}
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Nodes</span>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Nodes"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <div>
            <Table
              loading={loading}
              bordered
              size="small"
              columns={columns}
              dataSource={nodes.map((l, i) => {
                return { ...l, ...{ key: i } };
              })}
              pagination={{
                position: ["bottomRight"],
                onChange: onPageChange,
                pageSize: PAGE_SIZE,
                total: totalPages * PAGE_SIZE,
                showSizeChanger: false,
                current: pageNum,
                showTotal: (total) => (
                  <span className="fs-2 c-subtext">{`${totalCount} ${
                    totalCount === 1 ? "node" : "nodes"
                  }`}</span>
                ),
              }}
            ></Table>
          </div>
        </div>
      </div>
    </>
  );
}
