import {
  CheckCircleOutlined,
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { categoryTags, personaTags, servingTags } from "../../constants";
import { usStates } from "../../usStates";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { listOrgs, orgsSelector } from "../orgs/orgsSlice";
import { BulkUpdate } from "./BulkUpdate";
import { Reviews } from "./Reviews";
import { ServiceDetail } from "./ServiceDetail";
import {
  allServicesSelector,
  downloadServiceInteractions,
  downloadServices,
  listBenefitCalculators,
  searchGeography,
  searchServices,
} from "./servicesSlice";

const PAGE_SIZE = 50;
const { RangePicker } = DatePicker;

export function Services() {
  const dispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const { CSVReader } = useCSVReader();
  const [form] = Form.useForm();
  const [exportForm] = Form.useForm();
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const {
    data: services,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(allServicesSelector);
  const [pageNum, setPageNum] = useState(1);
  const [editOpened, setEditOpened] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({});
  const { orgsList } = useSelector(orgsSelector);
  const [geoResults, setGeoResults] = useState([]);
  const [bulkOpened, setBulkOpened] = useState(false);
  const [bulkData, setBulkData] = useState([]);
  const [reviewOpened, setReviewOpened] = useState(false);
  const [exportOpened, setExportOpened] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      messageApi.error(errors[0]);
    }
  }, [errors]);

  const loadServices = () => {
    dispatch(
      searchServices({
        query: searchQuery,
        filters: filters,
        page: pageNum,
        per: PAGE_SIZE,
      })
    );
  };

  useEffect(() => {
    loadServices();
    dispatch(listOrgs());
    dispatch(listBenefitCalculators());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "services",
      })
    );
  }, [dispatch]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <div
            className="link"
            onClick={() => {
              setCurrentService(record);
              setEditOpened(true);
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Program Name",
      dataIndex: "program",
      key: "program",
    },
    {
      title: "Offered By",
      dataIndex: "node_name",
      key: "node_name",
      render: (text, record) =>
        record.org_id ? (
          <Link to={`/orgs/${record.org_id}`}>{text}</Link>
        ) : (
          text
        ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text) => {
        return text.map((t, i) => (
          <Tag color="purple" className="mb-5" key={i}>
            {t}
          </Tag>
        ));
      },
    },
    {
      title: "Serving",
      dataIndex: "serving",
      key: "serving",
      render: (text) => {
        return text.map((t, i) => (
          <Tag color="cyan" className="mb-5" key={i}>
            {t}
          </Tag>
        ));
      },
    },
    {
      title: "Personas",
      dataIndex: "personas",
      key: "personas",
      render: (text) => {
        return text.map((t, i) => (
          <Tag color="green" className="mb-5" key={i}>
            {t}
          </Tag>
        ));
      },
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      key: "coverage",
      render: (text) => (
        <>
          {text?.zips?.length > 0 &&
            text.zips.map((t, i) => (
              <Tag color="gold" className="mb-5" key={i}>
                {t}
              </Tag>
            ))}
          {text?.cities?.length > 0 &&
            text.cities.map((t, i) => (
              <Tag color="orange" className="mb-5" key={i}>
                {t}
              </Tag>
            ))}
          {text?.counties?.length > 0 &&
            text.counties.map((t, i) => (
              <Tag color="volcano" className="mb-5" key={i}>
                {t}
              </Tag>
            ))}
          {text?.states?.length > 0 &&
            text.states.map((t, i) => (
              <Tag color="magenta" className="mb-5" key={i}>
                {t}
              </Tag>
            ))}
        </>
      ),
    },
    {
      title: "Trust",
      dataIndex: "trust",
      key: "trust",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating, record) => {
        return (
          <div
            className="link"
            onClick={() => {
              setCurrentService(record);
              setReviewOpened(true);
            }}
          >
            {rating}
          </div>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let data = JSON.parse(JSON.stringify(values));
    const { query, ...filters } = data;
    if (filters.tags) {
      let tags = [];
      filters.tags.forEach((t) => {
        if (t.length === 1) {
          tags = [...tags, ...categoryTags[t[0]].map((c) => `${t[0]} > ${c}`)];
        } else {
          tags = [...tags, `${t[0]} > ${t[1]}`];
        }
      });
      filters.tags = tags;
    }
    if (filters.coverage_zips) {
      filters.coverage_zips = filters.coverage_zips?.join("(,)");
    }
    if (filters.coverage_cities) {
      filters.coverage_cities = filters.coverage_cities?.join("(,)");
    }
    if (filters.coverage_counties) {
      filters.coverage_counties = filters.coverage_counties?.join("(,)");
    }
    if (filters.coverage_states) {
      filters.coverage_states = filters.coverage_states?.join("(,)");
    }
    setSearchQuery(query ? query : "");
    setFilters(filters);
    dispatch(
      searchServices({
        query: query ? query : "",
        filters: filters,
        page: 1,
        per: PAGE_SIZE,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      searchServices({
        query: searchQuery,
        filters: filters,
        page: page,
        per: PAGE_SIZE,
      })
    );
  };

  const downloadCsv = () => {
    if (totalCount > 250) {
      messageApi.error(
        "Please narrow down your search to less than 250 services to download"
      );
      return;
    }
    dispatch(
      downloadServices({
        query: searchQuery,
        filters: filters,
        page: 1,
        per: totalCount,
      })
    ).then((res) => {
      if (res.type === "services/download/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `services-${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        messageApi.error("Failed to download services data");
      }
    });
  };

  const searchGeo = (query, scope) => {
    setGeoResults([]);
    dispatch(searchGeography({ query, scope })).then((res) => {
      if (res.type === "services/searchGeography/fulfilled") {
        setGeoResults(res.payload.results);
      } else {
        messageApi.error({
          content: "Error retrieving data",
          icon: <CheckCircleOutlined />,
        });
      }
    });
  };

  const exportInteractions = (values) => {
    setExportLoading(true);
    dispatch(
      downloadServiceInteractions({
        start: values.range[0].toISOString(),
        end: values.range[1].toISOString(),
      })
    ).then((res) => {
      setExportLoading(false);
      if (res.type === "services/downloadServiceInteractions/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `interactions_${values.range[0].toISOString()}_to_${values.range[1].toISOString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        setExportOpened(false);
        exportForm.resetFields();
      } else {
        messageApi.error("Failed to export interactions");
      }
    });
  };

  return (
    <>
      {messageContext}
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap gutter={16}>
            <Col span={15}>
              <span className="fs-5 fw-300">Services</span>
            </Col>
            <Col span={4}>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setExportOpened(true)}
              >
                Export Interactions
              </Button>
            </Col>
            <Col className="ta-r" span={3}>
              <Tooltip
                title="CSV file with headers - id, time, value, cost, trust"
                color="#4867b1"
              >
                <div>
                  <CSVReader
                    onUploadAccepted={(results) => {
                      setBulkData(results.data);
                      setBulkOpened(true);
                    }}
                  >
                    {({ getRootProps }) => {
                      return (
                        <Button icon={<PlusOutlined />} {...getRootProps()}>
                          Bulk Update
                        </Button>
                      );
                    }}
                  </CSVReader>
                </div>
              </Tooltip>
            </Col>
            <Col className="ta-r" span={2}>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  setCurrentService(null);
                  setEditOpened(true);
                }}
              >
                New
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Count"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Form form={form} onFinish={handleSearch}>
            <Row gutter={24} align="top">
              <Col span={21}>
                <div>
                  <Form.Item name="query">
                    <Input
                      // onChange={(e) => setSearchQuery(e.target.value)}
                      // value={searchQuery}
                      className={`w-100`}
                      placeholder="Search query here..."
                    />
                  </Form.Item>
                </div>
                <div className="mt-10">
                  <Row gutter={16} align="middle">
                    <Col span={1}>
                      <div className="fs-1 c-subtext ta-r">AND</div>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="org_id">
                        <Select
                          allowClear
                          showSearch
                          placeholder="Orgs"
                          options={orgsList.map((o) => ({
                            label: `${o.id} - ${o.name}`,
                            value: o.id,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="tags">
                        <Cascader
                          allowClear
                          showSearch
                          multiple
                          placeholder="Tags"
                          options={Object.keys(categoryTags).map((t) => ({
                            label: t,
                            value: t,
                            children: categoryTags[t].map((c) => ({
                              label: c,
                              value: c,
                            })),
                          }))}
                        ></Cascader>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="serving">
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Serving"
                          options={servingTags.map((t) => ({
                            label: t,
                            value: t,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="personas">
                        <Select
                          allowClear
                          showSearch
                          mode="multiple"
                          placeholder="Personas"
                          options={personaTags.map((t) => ({
                            label: t,
                            value: t,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="mt-10">
                  <Row gutter={16} align="middle">
                    <Col span={1}>
                      <div className="fs-1 c-subtext ta-r">OR</div>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="coverage_zips">
                        <Select
                          placeholder="Zips"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={geoResults.map((t) => ({
                            label: t,
                            value: t,
                          }))}
                          onSearch={(val) => {
                            searchGeo(val, "zips");
                          }}
                          onDropdownVisibleChange={() => setGeoResults([])}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="coverage_cities">
                        <Select
                          placeholder="Cities"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={geoResults.map((t) => ({
                            label: t,
                            value: t,
                          }))}
                          onSearch={(val) => {
                            searchGeo(val, "cities");
                          }}
                          onDropdownVisibleChange={() => setGeoResults([])}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="coverage_counties">
                        <Select
                          placeholder="Counties"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={geoResults.map((t) => ({
                            label: t,
                            value: t,
                          }))}
                          onSearch={(val) => {
                            searchGeo(val, "counties");
                          }}
                          onDropdownVisibleChange={() => setGeoResults([])}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="coverage_states">
                        <Select
                          placeholder="States"
                          allowClear
                          showSearch
                          mode="multiple"
                          options={usStates.map((t) => ({
                            label: t.name,
                            value: t.code,
                          }))}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={3} className="ta-r">
                <Button
                  htmlType="submit"
                  className={`w-100`}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          <Divider />
          {totalCount > 0 && (
            <div className="ta-r">
              <Popconfirm
                title={`Are you sure you want to export ${totalCount} ${
                  totalCount === 1 ? "service" : "services"
                }?`}
                onConfirm={downloadCsv}
                okText="Yes"
                cancelText="No"
                placement="left"
              >
                <Button icon={<DownloadOutlined />}>Export</Button>
              </Popconfirm>
            </div>
          )}
          <Table
            loading={loading}
            bordered
            size="small"
            columns={columns}
            className="mt-15"
            dataSource={services.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: (total) => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "service" : "services"
                }`}</span>
              ),
            }}
          />
        </div>
      </div>

      <ServiceDetail
        open={editOpened}
        id={currentService?.id}
        onSave={() => {
          setCurrentService(null);
          setEditOpened(false);
          loadServices();
        }}
        onCancel={() => {
          setCurrentService(null);
          setEditOpened(false);
        }}
      />

      <Modal
        title="Bulk Update"
        open={bulkOpened}
        footer={null}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        width={1000}
        style={{
          top: 32,
        }}
      >
        <div className="pt-10">
          <BulkUpdate
            data={bulkData}
            onCancel={() => {
              setBulkOpened(false);
              setBulkData([]);
            }}
          />
        </div>
      </Modal>

      <Modal
        open={reviewOpened}
        title={`Service ${currentService?.id} - Net Rating: ${currentService?.rating}`}
        onCancel={() => {
          setCurrentService(null);
          setReviewOpened(false);
        }}
        footer={null}
        destroyOnClose={true}
        closable={true}
        width={750}
      >
        <Reviews id={currentService?.id} />
      </Modal>

      <Modal
        title="Export Interactions"
        open={exportOpened}
        footer={null}
        width={500}
        onCancel={() => {
          setExportOpened(false);
          exportForm.resetFields();
        }}
      >
        <div className="mt-30">
          <Form
            form={exportForm}
            requiredMark={false}
            onFinish={exportInteractions}
            layout="vertical"
          >
            <Form.Item
              name="range"
              label="Date Range (in PST)"
              rules={[{ required: true, message: "Please input date range" }]}
            >
              <RangePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>
            <Form.Item className="ta-r mt-35 mb-10">
              <Button type="primary" htmlType="submit" loading={exportLoading}>
                Export
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
